<template>
    <div class="container-incentives-list">
        <div class="incentives-list-row" v-if="showAddBtn">
            <div class="btn-add" @click="newItem" v-if="canAddNew && typePage != 3">
                <span v-if="typePage != 4">Додати запис</span>
                <span v-if="typePage == 4">Подати проект</span>
            </div>
            <p style="text-align: center; font-weight: 500; padding: 20px 10px;" v-else-if="!canAddNew && typePage != 3">
                Додати запис можливо після модерації попереднього
            </p>

        </div>
        <div class="incentives-list-row" v-if="typePage != 3">
            <div v-for="item in incentivesList" class="incentives-list-item">
                <div class="item-main">
                    <div class="item-title">
                        {{item.title || (item.event ? item.event.title : null) || item.description}}
                    </div>
                    <div class="item-status" v-if="item.status">
                        {{item.statusStr}}
                    </div>
                    <div class="item-date" v-if="item.date">
                        {{item.date}}
                    </div>
                    <div class="item-date" v-if="item.event">
                        {{item.event.date}}
                    </div>
                </div>
                <div class="item-edit" v-if="item.user_id == userId">
                    <div class="btn-view">
                        <img src="../assets/images/eye.svg" alt="edit" @click="viewIncentives(item.id)">
                    </div>
                    <div class="btn-edit">
                        <img src="../assets/images/edit_black.png" alt="edit" @click="editIncentives(item.id)">
                    </div>
                    <div class="btn-delete">
                        <img src="../assets/images/delete_black.png" alt="delete" @click="openPopup(item)">
                    </div>
                </div>
                <div class="item-edit" v-else>
                    <div class="btn-view">
                        <img src="../assets/images/eye.svg" alt="edit" @click="viewIncentives(item.id)">
                    </div>
                </div>
            </div>
        </div>
        <div class="plug" v-else-if="typePage == 3">
            <img src="../assets/images/develop.svg" alt="">
        </div>
        <div class="help-container" v-if="typePage == 1">
            <div @click="activeHelpList = !activeHelpList" class="tooltip-btn">Як це працює ?</div>
            <div v-if="activeHelpList" class="help-list">
                <div class="help-item">
                    Крок 1. Натисни кнопку «Додати запис»
                </div>
                <div class="help-item">
                    Крок 2. Обери у календарі потрібну дату заходу*
                </div>
                <div class="help-item">
                    Крок 3. Обери потрібний захід.
                </div>
                <div class="help-item">
                    Крок 4. Додай будь-який файл із заходу.
                </div>
                <div class="help-item">
                    Крок 5. Поділись враженнями і пропозиціями.
                </div>
                <div class="help-item">
                    Крок 6. Натисни «Зберегти».
                </div>
                <div class="help-item">
                    *Не знайшов дату або не знаєш назву? Шукай у <a href="/events" trarget="_blanck">календарі подій.</a>
                </div>

            </div>
        </div>
        <div class="help-container project-helper" v-if="typePage == 4">
            <div @click="activeHelpList = !activeHelpList" class="tooltip-btn">Як це працює ?</div>
            <div v-if="activeHelpList" class="help-list">
                <div class="help-item">
                    Маєш ідею? Готовий поділитись знаннями чи зробити волонтерський проект? Ми раді допомогти тобі реалізувати ТВОЇ ідеї. Заповнюй форму, детально розписавши свою ідею і після модерації твій проект може бути винесено на голосування для інших партійців. Проект, який переможе – буде реалізовано партією. Впроваджуй свої ідеї і долучайся до ініціатив партійців
                </div>
                <div class="help-item">
                    Крок 1. Натисни кнопку «Подати проект»
                </div>
                <div class="help-item">
                    Крок 2. Заповни поля.
                </div>
                <div class="help-item">
                    Крок 3. Прикріпи важливі файли-додатки.
                </div>
                <div class="help-item">
                    Крок 4. Не забудь додати ініціаторів.
                </div>
                <div class="help-item">
                    Крок 5. Натисни «Зберегти»
                </div>
                <div class="help-item help-description">
                    Важливо! Проектам присвоюються статуси:
                </div>
                <div class="help-item">
                        <strong>Отримано</strong> – проект надіслано адміністратору.
                </div>
                <div class="help-item">
                    <strong>Отримано з редагуванням</strong> – у разі якщо один і той самий проект надсилався на модерацію двічі.
                </div>
                <div class="help-item">
                    <strong>На модерації</strong>– переглядається адміністратором.
                </div>
                <div class="help-item">
                    <strong>Допрацювати</strong> – проект містить помилки, які потрібно виправити.
                </div>
                <div class="help-item">
                    <strong>Відхилений</strong> – надається пояснення відхилення.
                </div>
                <div class="help-item">
                    <strong>Висунутий на голосування</strong> – проект погоджено на голосування.
                </div>
                <div class="help-item">
                    <strong>На голосуванні</strong> – статус надається автоматично, після початку голосування
                </div>
                <div class="help-item">
                    <strong>Отримано найбільше голосів</strong> – статус надається автоматично після завершення голосування, на якому було виставлено цей проект
                </div>
            </div>
        </div>
        <div class="popup-delete" v-if="popupDelete">
            <div class="popup-title">
                Видалити подію {{itemToDelete.title || (itemToDelete.event ? itemToDelete.event.category : null) || itemToDelete.description}} ?
            </div>
            <div class="btn-container">
                <button class="popup-btn" @click="popupDelete = false">Відмінити</button>
                <button class="popup-btn" @click="deleteIncentives(itemToDelete.id)">Видалити</button>
            </div>
        </div>
    </div>
</template>

<script>
import IncentivesService from "../services/IncentivesService";

export default {
    name: "IncentivesList",
    data () {
        return {
            userId: null,
            canAddNew : true,
            showAddBtn: false,
            minStatus: 0,
            incentivesList: [],
            activeHelpList: false,
            typePage: 1,
            urlListUpdate: [
                {type: 1, url: '/encouragement/active-participant/update/'},
                {type: 2, url: '/encouragement/volunteer/update/'},
                {type: 3, url: '/encouragement/media-active/update/'},
                {type: 4, url: '/encouragement/user-project/update/'}
            ],
            urlListView: [
                {type: 4, url: '/encouragement/user-project/view/'}
            ],
            urlListCreate: [
                {type: 1, url: '/encouragement/active-participant/create'},
                {type: 2, url: '/encouragement/volunteer/create'},
                {type: 3, url: '/encouragement/media-active/create'},
                {type: 4, url: '/encouragement/user-project/create'}
            ],
            popupDelete: false,
            itemToDelete: null
        }
    },

    watch: {
        userId: function () {
            this.getIncentivesList()
        },
        incentivesList: function () {
            this.incentivesList.forEach(item => {
                if (typeof item.date === 'number'){
                    item.date = this.convertDate(item.date)
                }
                if(item.status < this.minStatus && item.user_id === this.userId){
                    this.canAddNew = false;
                }
            })
        },
        '$route.path'() {
            this.showAddBtn = false
            this.typePage = +this.$route.params.type
            this.getIncentivesList()
            this.canAddNew = true
        },
        typePage(){
            if (this.typePage == 4){
                this.getMinimalStatusForAdding()
            }
        }
    },

    created() {
        this.getMinimalStatusForAdding();
        this.getUserId()
        this.getIncentivesList()
    },

    mounted() {
        if (this.$route.params){
            this.typePage = this.$route.params.type
        }
    },

    methods: {
        async getUserId() {
            this.userId = await IncentivesService.getUserId()
        },
        async getIncentivesList() {
            if (this.userId){
                this.incentivesList = await IncentivesService.getIncentivesList(this.userId, this.typePage)
            }
            this.showAddBtn = true
        },
        async getMinimalStatusForAdding(){
            this.minStatus = await IncentivesService.getMinimalStatusForAdding();
        },
        convertDate(date) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            let d = new Date(date)
            return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('.')
        },
        deleteIncentives(id) {
            IncentivesService.deleteIncentives(id)
            let index = this.incentivesList.findIndex(el => el.id === id)
            this.incentivesList.splice(index, 1)
            this.popupDelete = false
        },
        editIncentives(id) {
            let link = this.urlListUpdate.find(item => item.type == this.typePage)
            this.$router.push(link.url + id)
        },
        viewIncentives(id) {
            if (this.typePage == 1) {
                this.$router.push(`/encouragement/active-participant/view/${id}`)
            }
            else {
                let link = this.urlListView.find(item => item.type == this.typePage)
                this.$router.push(link.url + id)
            }
        },
        newItem(){
            let link = this.urlListCreate.find(item => item.type == this.typePage)
            this.$router.push(link.url)
        },
        openPopup(item){
            this.itemToDelete = item
            this.popupDelete = true
        },
        /* hideTooltip(event){
            if(!(event.target.classList.contains('help-list') || event.target.classList.contains('help-item'))){
                this.activeHelpList = false
            }
        } */
    },
    
}
</script>

<style scoped lang="less">
.plug{
    width: 100%;
    margin-top: 100px;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    img{
        display: block;
    }
}
.help-container{
    margin-top: 20px;
    position: relative;

    .tooltip-btn{
        width: 160px;
        height: 50px;
        margin: 0px auto 20px auto;
        border-radius: 10px;
        box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);
        background-color: white;
        border: 3px solid #1a2744;
        color: #1a2744;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-family: @secondFontFamily;
    }
    .help-list{
        position: absolute;
        background-color: white;
        padding: 40px;
        width: 360px;
        left: 50%;
        transform: translate(-50%, 0px);
        bottom: auto;
        max-width: 100vw;

        .help-description{
            margin-top: 20px!important;
        }
        .help-item{
            font-family: @defaultFontFamily;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.11px;
            color: #1a2744;
            margin-top: 5px;
        }
    }
}
.project-helper{
    .help-list{
        position: absolute;
        background-color: white;
        padding: 40px;
        width: 62vw;
        left: 50%;
        top: 51px;

        @media (max-width: 992px){
            width: 100vw;
        }
    }
}
    .container-incentives-list{
        background-color: #f5f5f5;
        width: 100%;
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 21px;
        padding-bottom: 10%;
        .incentives-list-row{
            width: 100%;
            .btn-add{
                width: 160px;
                height: 50px;
                margin: 0px auto 20px auto;
                border-radius: 10px;
                box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);
                background-color: #1a2744;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                span{
                    font-family: @secondFontFamily;
                }
            }
            .incentives-list-item{
                min-height: 68px;
                border-radius: 10px;
                background-color: #fff;
                margin: 0px 40px 5px 40px;
                display: flex;
                justify-content: space-between;
                @media (max-width: 768px){
                    margin: 0 10px 5px 10px;
                }
                .item-main{
                    padding: 0 40px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;
                    word-break: break-word;
                    
                    @media screen and (max-width: 575px) {
                        padding-left: 10px;
                    }

                    .item-title{
                        font-family: @secondFontFamily;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.33;
                        color: #1a2744;
                        max-width: 40%;
                        display: -webkit-box;
                        -webkit-line-clamp: 7;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        @media (max-width: 768px){
                            word-break: break-word;
                            max-width: 60%;
                            font-size: 15px;
                        }
                    }
                    .item-date{
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.71;
                        color: #9da3ae;
                    }
                    .item-status{
                        font-family: @secondFontFamily;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 1.33;
                        color: #1a2744;

                        @media (max-width: 768px){
                            word-break: break-word;
                            font-size: 15px;
                        }
                    }
                }
                .item-edit{
                    display: flex;
                    align-items: center;
                    .btn-edit, .btn-delete, .btn-view{
                        cursor: pointer;
                        margin-right: 25px;

                        @media screen and (max-width: 575px) {
                            margin-right: 7px;
                        }
                    }
                }
            }
        }
        .popup-delete{
            position: fixed;
            background-color: #f5f5f5;
            top: 50%;
            left: 50%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: column;
            width: 610px;
            min-height: 196px;
            border-radius: 10px;
            box-shadow: 0 24px 24px -14px rgba(157, 163, 174, 0.1);
            border: solid 1px #cdd4e0;

            @media screen and (max-width: 992px) {
                transform: translate(-50%, 0);
                max-width: 100%;
            }

            .popup-title{
                font-family: @secondFontFamily;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
                color: #1a2744;
                padding: 0 20px;
            }
            .btn-container{
                display: flex;
                justify-content: space-around;
                width: 100%;

                @media screen and (max-width: 992px) {
                    flex-wrap: wrap;
                }

                .popup-btn{
                    width: 220px;
                    height: 50px;
                    border-radius: 10px;
                    box-shadow: 0 24px 24px -14px rgba(26, 39, 68, 0.35);
                    background-color: #1a2744;
                    font-family: @secondFontFamily;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.33;
                    color: #fff;

                    @media screen and (max-width: 992px) {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
</style>
