import axios from "axios";

export default {
    getUserId() {
        return axios.get('/api/user').then(resp => resp.data.user.id);
    },
    getIncentivesList(id, type) {
        return axios.get('/api/promotion?', { params: { user_id: id, type: type } }).then(resp => resp.data.data);
    },
    deleteIncentives(id) {
        return axios.post('/api/promotion/delete?id=' + id).then(resp => resp.data)
    },
    getMinimalStatusForAdding(){
        return axios.get('/api/promotion/minimal-status-for-adding').then(resp => resp.data.data);
    }
}
